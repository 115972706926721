export type EventsMap = {
    [eventName: string]: (...args: any[]) => any;
};

export type EventArgType<T extends EventsMap, K extends keyof T> =
    T[K] extends (...args: infer Args) => void ? Args : never;

class EventEmitter<T extends EventsMap, TAsync extends EventsMap = EventsMap> {
    private events: any;

    constructor() {
        this.events = {};
    }

    on<K extends keyof T>(event: K, listener: T[K]) {
        if (typeof this.events[event] !== 'object') {
            this.events[event] = [];
        }

        this.events[event].push(listener);
        return () => this.off(event, listener);
    }

    onAsync<K extends keyof TAsync>(event: K, listener: TAsync[K]) {
        const asyncListener = (...args: EventArgType<TAsync, K>): Promise<void> => {
            return listener.apply(this, args);
        }

        return this.on(event as any, asyncListener as any);
    }

    off<K extends keyof T>(event: K, listener: T[K]) {
        if (typeof this.events[event] !== 'object') {
            return;
        }

        const idx = this.events[event].indexOf(listener);
        if (idx > -1) {
            this.events[event].splice(idx, 1);
        }
    }

    removeAllListeners() {
        Object.keys(this.events).forEach(
            (event) => this.events[event].splice(0, this.events[event].length),
        );
    }

    emit<K extends keyof T>(event: K, ...args: EventArgType<T, K>) {
        if (typeof this.events[event] === 'object') {


            [...this.events[event]].forEach((listener) => listener.apply(this, args));

        }
    }
    
    emitAsync<K extends keyof TAsync>(event: K, ...args: EventArgType<TAsync, K>): Promise<Awaited<ReturnType<TAsync[K]>>[]> {
        if (typeof this.events[event] === 'object') {
            const listeners = this.events[event] as TAsync[K][];
            const promises = listeners.map((listener) => listener.apply(this, args));
            return Promise.all(promises);
        }

        return Promise.resolve([]);
    }

    once<K extends keyof T>(event: K, listener: T[K]) {

        const remove = this.on(event, ((...args: EventArgType<T, K>) => {
            remove();
            listener.apply(this, args);
        }) as T[K]);

        return remove;
    }
}

export default EventEmitter;
